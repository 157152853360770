import { apiEndpoints } from "../settings";
import i18n from "../i18n";

/**
 * API Error
 */
class ApiError extends Error {
  constructor(httpStatus, message, obj = {}) {
    super(message);
    this.httpStatus = httpStatus;
    this.name = "ApiError";
    this.obj = {
      code: httpStatus,
      message,
      ...obj,
    };
  }

  /**
   * Format the error using the same format used by API response errors
   * @returns {Object}
   */
  format() {
    return this.obj;
  }
}

/**
 * Make an API request
 *
 * @param {String} uri - API endpoint
 * @param {String} token - API token
 * @param {Object} body - Optional request body transmitted as JSON
 * @param {String} method - Optional HTTP request method. Default: GET, POST when `body` is set
 * @returns {Promise} Promise that either resolves with the parsed response body or rejects with an ApiError
 */
const baseApiCall = async (uri, token, body, method = "GET") => {
  const headers = new Headers();
  if (token) {
    headers.append("X-CSRF-Token", token);
  }
  if (body) {
    headers.append("Content-Type", "application/json");
  }

  // TODO remove in production
  if (uri.match(/mock.pstmn.io\/(article|compare|translation)$/)) {
    headers.append("x-mock-match-request-body", true);
  }

  const request = new Request(uri, { // nosemgrep: nodejs_scan.javascript-ssrf-rule-node_ssrf
    body: body ? JSON.stringify(body) : null,
    cache: "no-cache",
    headers,
    method: body && method === "GET" ? "POST" : method,
    mode: "cors",
    credentials: "include",
  });

  let response;
  try {
    response = await fetch(request);
  } catch (e) {
    throw new ApiError(-1, i18n.t("errors.unknown"));
  }

  let data;
  try {
    data = await response.json();
  } catch (e) {
    throw new ApiError(-1, i18n.t("errors.unknown"));
  }

  if (!response.ok) {
    const message = data.error.message || i18n.t("errors.unknown");
    throw new ApiError(response.status, message, data.data);
  }

  if (data.error) {
    // make sure that data is empty when error is set
    data.data = {};

    throw new ApiError(data.error.code, data.error.message, data.error);
  }

  return data;
};

/**
 * Get an API authentication token
 *
 * @param {Boolean} forceRefresh - Force getting a new token from the API
 * @returns {Promise<String>} Promise that resolves with an API token
 */
const getToken = async (forceRefresh = false) => {
  const KEY = "token";

  if (forceRefresh) {
    sessionStorage.removeItem(KEY);
  }

  let token = sessionStorage.getItem(KEY);
  if (!token) {
    try {
      const { data } = await baseApiCall(apiEndpoints.token);
      token = data.token;
      sessionStorage.setItem(KEY, token);
    } catch (e) {
      const handledErrorCodes = [401, 403];

      const { code, message, url } = e.format();
      if (handledErrorCodes.includes(code)) {
        // go to login when retrieving a token fails
        if (code === 401 && url) {
          window.setTimeout(() => {
            window.location.href = url;
          }, 2000);
        }

        throw new ApiError(code, message, { url });
      }
    }
  }

  return token;
};

/**
 * Make an API call, automatically make sure that the API token is valid
 *
 * @param {*} uri - API endpoint
 * @param {*} body - Optional request body transmitted as JSON
 * @param {*} method - Optional HTTP request method. Default: GET, POST when `body` is set
 * @returns {Promise} Promise that either resolves with the parsed response body or rejects with an ApiError
 */
const apiCall = async (uri, body, method) => {
  try {
    const token = await getToken();
    return await baseApiCall(uri, token, body, method);
  } catch (e) {
    if (e.format().code === 401) {
      /* when the first request has failed due to an auth error, force-refresh
       * the auth token and try again
       */
      const token = await getToken(true);
      return await baseApiCall(uri, token, body, method);
    }
    throw e;
  }
};

export default apiCall;
