import "what-input";

import BuerkertVueComponentsPlugin from "@webprojects/ui-pattern-library";
import { createApp } from "vue";
import VueMousetrap from "@4rk/vue-mousetrap";
import VueRovingTabindex from "@4rk/vue-roving-tabindex";
import VueTracking from "./vue-tracking";

import App from "./components/App.vue";
import dispatchTrackingEvent from "./lib/dispatchTrackingEvent";
import i18n, { loadLanguageTranslations } from "./i18n";
import store from "./store";
import router from "./router";

import "./main.scss";

// NodeJS Update: Has to be commented out to avoid error in console during compiling application
//require("babel-plugin-require-context-hook/register")();

// initialize app
const init = async () => {
  try {
    await store.dispatch("global/loadUser");
    await loadLanguageTranslations(store.state.global.user.data.language);

    // global/loadUser does not throw on its own
    if (store.state.global.user.error) {
      store.commit("global/setHasError", true);
      throw new Error();
    }

    store.commit("global/setIsInitialized", true);
  } catch (e) {
    store.commit("global/setHasError", true);
  }
};

init();

const app = createApp(App);

app.use(store);
app.use(router);
app.use(i18n);
app.use(BuerkertVueComponentsPlugin);
app.use(VueMousetrap);
app.use(VueRovingTabindex);
app.use(VueTracking);

app.config.globalProperties.trackingOptions = {
  dispatch: dispatchTrackingEvent,
};

app.mount("#app");
